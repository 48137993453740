@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

$font-family-sans-serif: "Open Sans", sans-serif;
$acronis-color: #00204d;
$primary: $acronis-color;
$secondary: #9bc225;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "Acronis";
  src: url("../../public/Acronis.woff") format("woff");
}

@font-face {
  font-family: "Acronis Light";
  src: url("../../public/Acronis-Light.woff") format("woff");
}

h2 {
  font-family: "Acronis Light", sans-serif;
}

.wrapper {
  background-image: linear-gradient(180deg, #e5f1ff 0, #fff);
  position: relative;
  overflow: auto;
}

.cyber-cloud-description {
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  color: rgba(36, 49, 67, 0.9);
  a {
    color: $acronis-color;
    &:hover {
      text-decoration: none !important;
    }
  }

  .logo {
    margin: 0;
    display: block;
    font: 300 39px/38px "Acronis Light", sans-serif;

    image,
    svg {
      width: 135px;
      height: 29px;
      display: block;
      margin: 0 0 12px;
    }
  }
}

a {
  color: #0275d8;
  text-decoration: none;

  &:focus,
  &:hover {
    color: #014c8c;
    text-decoration: underline;
  }

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

.form-container {
  border: 1px solid #e3ecf9;
  box-shadow: 0 0 10px 0 #cfddeeb8;
  border-radius: 2px;
  background-color: #fff;
  .title {
    color: #00204e;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    display: block;
    text-align: center;
    font-weight: 600;
    line-height: 32px;
  }
  .subtitle {
    display: block;
    font-size: 18px;
    margin-top: 16px;
    line-height: 24px;
    padding: 0 0 24px;
    text-align: center;
  }
  .notes {
    font-size: 12px;
    line-height: 16px;
    color: rgba(36, 49, 67, 0.6);
    text-align: center;
    padding: 12px 0 0;
  }
  .input-container {
    height: 4.5rem;
  }
  .form-check {
    label {
      cursor: pointer;
    }
  }

  .btn {
    margin: 0;
    padding: 0;
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    background-color: #9bc225;
  }
}

input, select {
  height: 48px !important;
  color: black !important;
}

input[type="checkbox"] {
  height: auto !important;
}

.media-body {
  h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}

.report {
  color: $acronis-color;
  .part {
    font-size: large;
    margin-bottom: 1rem;
  }
}
